import React, { Component } from 'react'
import { withFirebase } from '../firebase/context'

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential'
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  onSubmit = event => {
    const { firebase, onSignIn } = this.props
    firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        const person = {
          email: socialAuthUser.additionalUserInfo.profile.email,
          name: socialAuthUser.additionalUserInfo.profile.name,
          roles: [],
          socialId: socialAuthUser.additionalUserInfo.profile.id,
          uid: socialAuthUser.user.uid,
        }
        return firebase.user(person)
      })
      .then(() => {
        this.setState({ error: null })
        onSignIn()
      })
      .catch(error => {
        let returnMessage = error.message
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          returnMessage = ERROR_MSG_ACCOUNT_EXISTS
        }
        this.setState({ error: returnMessage })
      })

    event.preventDefault()
  }

  render() {
    const { error } = this.state

    return (
      <form onSubmit={this.onSubmit}>
        <button
          type="submit"
          className="bg-blue white br2 pa3 bn pointer hover-bg-dark-blue bg-animate"
        >
          Acessar com o Facebook
        </button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

export default withFirebase(SignInFacebookBase)
