import React, { Component } from 'react'

import { withFirebase } from '../firebase/context'
import SignInFacebookBase from './SignInWithFacebookBase'

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
}

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { email, password } = this.state
    const { firebase, onSignIn } = this.props

    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(user => {
        const person = {
          email: user.user.email,
          name: '',
          roles: [],
          socialId: null,
          uid: user.user.uid,
        }
        return firebase.user(person)
      })
      .then(() => {
        this.setState({ error: null })
        onSignIn()
      })
      .catch(error => {
        let friendlyMessage
        switch (error.code) {
          case 'auth/wrong-password':
            friendlyMessage = 'A senha informada não é válida ou a conta está atrelada ao Facebook'
            break
          default:
            friendlyMessage = error.message
            break
        }
        this.setState({ error: { message: friendlyMessage } })
      })
    event.preventDefault()
  }

  render() {
    const { email, password, error } = this.state

    const isInvalid = password === '' || email === ''

    return (
      <div className="box mw6-ns center cf pa4">
        <form onSubmit={this.onSubmit} className="cf db">
          <div className="input mb3">
            <input
              name="email"
              value={email}
              onChange={event => this.setState({ [event.target.name]: event.target.value })}
              type="text"
              placeholder="Email"
            />
          </div>
          <div className="input mb3">
            <input
              name="password"
              value={password}
              onChange={event => this.setState({ [event.target.name]: event.target.value })}
              type="password"
              placeholder="Password"
            />
          </div>
          <div>
            <button disabled={isInvalid} type="submit" className="btn btn-primary w-100">
              Acessar Conta
            </button>
          </div>
          {error && <p className="red">{error.message}</p>}
        </form>
      </div>
    )
  }
}

const SignInForm = withFirebase(SignInFormBase)
const SignInFacebook = withFirebase(SignInFacebookBase)

export default SignInForm

export { SignInFacebook }

// import React, { Component } from 'react'
// import { StyledFirebaseAuth } from 'react-firebaseui'

// import { withFirebase } from '../firebase/context'
// import Header from '../header'
// import s from '../css/css.module.css'

// const INITIAL_STATE = {
//   email: '',
//   password: '',
//   error: null,
// }

// class SignIn extends Component {
//   constructor(props) {
//     super(props)
//     this.state = { ...INITIAL_STATE }
//   }
//   // state = {
//   //   isSignedIn: false // Local signed-in state.
//   // }

//   render = () => {
//     return (
//       <article className={`${s.sansSerif}`}>
//         <Header siteTitle="Gatsby Firebase Starter" />
//         <main id="sign-in">
//           <h4>Hey, please login to continue</h4>
//           <h5>This is just firebase login test</h5>
//           <StyledFirebaseAuth
//             uiConfig={{
//               signInFlow: 'popup',
//               signInOptions: [
//                 this.props.firebase.auth.EmailAuthProvider.PROVIDER_ID,
//               ],
//             }}
//             firebaseAuth={this.props.firebase.auth()}
//           />
//         </main>
//         <footer className={`${s.bgNavy} ${s.tc} ${s.white} ${s.w100} ${s.f7}`}>
//           <div className={`${s.center} ${s.mw8Ns} ${s.pa3}`}>
//             Copyright &copy 2018-{(new Date()).getFullYear()} Immortal. Todos os direitos reservados.
//           </div>
//         </footer>
//       </article>
//     )
//   }
// }

// export default withFirebase(SignIn)
