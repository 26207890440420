/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { graphql } from 'gatsby'
import ptBR from 'date-fns/locale/pt-BR'
import { format, formatDistance } from 'date-fns'
import Scrollchor from 'react-scrollchor'
import { FacebookProvider, EmbeddedPost } from 'react-facebook'
import { TwitterTweetEmbed } from 'react-twitter-embed'
import InstagramEmbed from 'react-instagram-embed'

import getFirebase from '../components/firebase/firebase'
import GuestbookEntry from '../components/Memorials/templates/GuestbookEntry'
import AuthUserContext from '../components/Session/AuthUserContext'
import { SignInFacebook } from '../components/SignIn'
import { randomString, slugify } from '../components/helpers/UIHelpers'
import SEO from '../components/seo'
import MemorialHeader from '../components/Memorials/templates/MemorialHeader'
import MemorialNavBar from '../components/Memorials/templates/NavBar'
import {
  FuneralInfo,
  ImageAndMessage,
  ImageOnly,
  MessageOnly,
  QuoteAndMessage,
  YoutubeVideo,
} from '../components/Templates'

const SibApiV3Sdk = require('sib-api-v3-sdk')

const defaultClient = SibApiV3Sdk.ApiClient.instance

const TimelineItem = ({ ...props }) => {
  const { id, tribute } = props
  const {
    eventDay,
    eventMonth,
    eventYear,
    eventDate,
    image,
    message,
    quote,
    quoteBackgroundColor,
    quoteBackgroundImage,
    quoteFontColor,
    type,
    videoUrl,
  } = tribute
  let dateFormat = `d 'de' MMMM 'de' yyyy`
  if (eventMonth === 0) {
    dateFormat = `yyyy`
  } else if (eventDay === 0) {
    dateFormat = `MMMM 'de' yyyy`
  }

  const formattedDate = format(
    new Date(eventYear, eventMonth === 0 ? 0 : eventMonth - 1, eventDay === 0 ? 1 : eventDay),
    dateFormat,
    {
      awareOfUnicodeTokens: true,
      locale: ptBR,
    }
  )

  return (
    <div id={id} className="timeline-item relative mt2 mb5">
      <div className="timeline-img bg-light-blue flex items-center justify-center">
        <span className="material-icons white f3">photo</span>
      </div>
      <div className="timeline-item-content relative br2 bg-near-white">
        {type === 'ImageAndMessage' && (
          <ImageAndMessage
            eventDate={eventDate}
            eventDay={eventDay}
            eventMonth={eventMonth}
            eventYear={eventYear}
            image={image}
            message={message}
            type="memorial"
          />
        )}
        {type === 'ImageOnly' && <ImageOnly image={image} />}
        {type === 'MessageOnly' && (
          <MessageOnly
            eventDate={eventDate}
            eventDay={eventDay}
            eventMonth={eventMonth}
            eventYear={eventYear}
            message={message}
            type="memorial"
          />
        )}
        {type === 'QuoteAndMessage' && (
          <QuoteAndMessage
            eventDate={eventDate}
            eventDay={eventDay}
            eventMonth={eventMonth}
            eventYear={eventYear}
            message={message}
            quote={quote}
            quoteBackgroundColor={quoteBackgroundColor}
            quoteBackgroundImage={quoteBackgroundImage}
            quoteFontColor={quoteFontColor}
            type="memorial"
          />
        )}
        {type === 'YoutubeVideo' && (
          <YoutubeVideo
            eventDate={eventDate}
            eventDay={eventDay}
            eventMonth={eventMonth}
            eventYear={eventYear}
            videoUrl={videoUrl}
          />
        )}
        <span className="timeline-item-date dib f7 dark-gray nowrap pa1 top-1 mt1">
          {`${formattedDate.charAt(0).toUpperCase()}${formattedDate.slice(1)}`}
        </span>
      </div>
    </div>
  )
}

class Memorial extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      activeTab: 'memories',
      guestMessage: '',
      hasAlreadySigned: false,
      state: 'guestbookTypeChoice',
    }
    this.handleTabChange = this.handleTabChange.bind(this)
    this.signGuestBook = this.signGuestBook.bind(this)
  }

  componentWillMount() {
    // const { firestore, auth } = getFirebase()
    // console.log(auth)
  }

  handleTabChange = activeTab => {
    if (activeTab === 'guestbook') {
      this.userHasSignedGuestbook()
    }
    this.setState({ activeTab })
  }

  userHasSignedGuestbook = () => {
    const { data } = this.props
    const { firestore, auth } = getFirebase()
    const memorialId = data.person.memorial.id
    if (!auth.getUid()) {
      return
    }
    const memorialRef = firestore.collection('Memorials').doc(memorialId)
    const userRef = firestore.collection('Users').doc(auth.getUid())
    firestore
      .collection('Guests')
      .where('memorial', '==', memorialRef)
      .where('user', '==', userRef)
      .get()
      .then(results => {
        this.setState({ hasAlreadySigned: results.docs.length > 0 })
      })
      .catch(results => {
        this.setState({ hasAlreadySigned: false })
      })
  }

  signGuestBook = () => {
    this.handleTabChange('guestbook')
  }

  sendMail = (guestMessage, personId, personName, preApprovalRequired, userEmail, userName) =>
    new Promise(resolve => {
      const apiKey = defaultClient.authentications['api-key']
      apiKey.apiKey = process.env.GATSBY_SENDINBLUE_APIKEY
      const smtpApi = new SibApiV3Sdk.SMTPApi()
      const sendSmtpEmail = new SibApiV3Sdk.SendSmtpEmail()
      sendSmtpEmail.sender = {
        name: 'Immortalis',
        email: 'noreply@immortalis.com.br',
      }
      sendSmtpEmail.subject =
        guestMessage === ''
          ? `Immortalis - Alguém assinou o livro de visitas de ${personName}`
          : `Immortalis - Mensagem publicada no livro de visitas de ${personName}`
      if (guestMessage !== '') {
        sendSmtpEmail.htmlContent = `
          <p>Olá.</p>
          <p>Alguém acabou de publicar uma mensagem no memorial de <strong>${personName}</strong>.</p>
          ${
            preApprovalRequired
              ? '<p>Por favor, <a href="https://immortalis.com.br/signin">acesse sua conta</a> no Immortalis para autorizar sua publicação.</p>'
              : `
                <p className="em">&quot;${guestMessage}&quot;, enviada por ${userName}</p>
                <p>
                  <a href="https://immortalis.com.br/memorials/${slugify(
                    personName
                  )}/${personId}">Acesse o memorial</a>
                  para ler a homenagem postada.
                </p>
              `
          }
          <p>Equipe Immortalis</p>
        `
        sendSmtpEmail.textContent = `
          Alguém acabou de publicar uma mensagem no memorial de ${personName}.

          ${
            preApprovalRequired
              ? 'Por favor, acesse sua conta no Immortalis para autorizar sua publicação.'
              : 'Acesse o memorial para ler a homenagem postada.'
          }

          Equipe Immortalis
        `
      } else {
        sendSmtpEmail.htmlContent = `
          <p>Olá.</p>
          <p>Achamos que você gostaria de saber que <strong>${userName}</strong> acabou de assinar o Livro de Visitas no memorial de <strong>${personName}</strong>.</p>
          <p><a href="https://immortalis.com.br/memorials/${slugify(
            personName
          )}/${personId}">Clique aqui</a> para acessar o memorial.</p>
          <p>Equipe Immortalis</p>
        `
        sendSmtpEmail.textContent = `
          Olá, 

          Achamos que você gostaria de saber que ${userName} acabou de assinar o Livro de Visitas no memorial de ${personName}.

          Um abraço da Equipe Immortalis
        `
      }
      sendSmtpEmail.to = [{ email: userEmail }]
      smtpApi.sendTransacEmail(sendSmtpEmail).then(
        emailData => {
          resolve(emailData)
        },
        error => {
          resolve(false)
        }
      )
    })

  addToGuestBook = e => {
    const { data } = this.props
    const { firestore, auth } = getFirebase()
    const { guestMessage } = this.state
    const memorialId = data.person.memorial.id
    const { personId, personName } = data.person
    const { preApprovalRequired, userEmail } = data.person.memorial

    const memorialRef = firestore.collection('Memorials').doc(memorialId)
    const userRef = firestore.collection('Users').doc(auth.currentUser.uid)
    const guestbookData = {
      approved: !preApprovalRequired,
      dateCreated: new Date(),
      memorial: memorialRef,
      message: guestMessage,
      user: userRef,
    }

    const signGuestbook = itemData =>
      new Promise((resolve, reject) => {
        firestore
          .collection('Guests')
          .add(guestbookData)
          .then(guestbookAddedEntry => {
            firestore.runTransaction(t =>
              t
                .get(memorialRef)
                .then(memorialDoc => {
                  const obj = memorialDoc.get('guests') ? memorialDoc.get('guests') : []
                  obj.push(guestbookAddedEntry)
                  t.set(memorialRef, { guests: obj }, { merge: true })
                })
                .then(result => {
                  userRef.get().then(user => {
                    const userName = user.data().name
                    this.sendMail(
                      guestMessage,
                      personId,
                      personName,
                      preApprovalRequired,
                      userEmail,
                      userName
                    )
                    resolve(result)
                  })
                })
            )
          })
      })

    signGuestbook(guestbookData).then(() => {
      this.setState({
        state: 'guestbookSigned',
      })
    })
  }

  render() {
    const { data } = this.props
    const { birthday, deathday, memorial, personName } = data.person
    const {
      guests,
      memorialBackgroundColor,
      memorialIntroduction,
      personPhoto,
      socialPosts,
      tributes,
    } = memorial
    const { activeTab, guestMessage, hasAlreadySigned, state } = this.state
    const anchors = []
    let year = 0
    return (
      <>
        <SEO
          title={`Memorial de ${personName}`}
          description={memorialIntroduction}
          type="article"
          image={personPhoto}
        />
        <script
          type="text/javascript"
          src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5c6326c6a041fceb"
        />
        <MemorialHeader
          headerBackgroundColor={memorialBackgroundColor}
          memorialIntroduction={memorialIntroduction}
          name={personName}
          personBirthday={birthday}
          personDeathday={deathday}
          personPhoto={personPhoto}
          signGuestBook={this.signGuestBook}
        />
        <div className="relative z-1 mw8-ns ph3-ns center">
          <div
            className="absolute-l right-0 top-2 pa3 pa4-ns bg-light-blue white fw3 br3 mh3 mh0-ns w5-l mb5 nt5 mt3-l"
            style={{
              right: '-290px',
            }}
          >
            <h2 className="fw3 lh-title mt0 mb2">Eternize também suas lembranças.</h2>
            <p className="mt0 lh-copy white-70">
              Crie um memorial e registre para a eternidade as memórias do ente querido que partiu.
            </p>
            <a
              className="dib btn btn-white-secondary br-pill f6 w-auto w-100-l ph3 ph0-l fw4"
              href="/create-memorial/"
            >
              Criar Memorial
            </a>
          </div>
          <div className="flex tl w-100">
            <div
              className={`${
                activeTab === 'timeline' ? 'w-100 w-90-ns' : 'w-100'
              } box br0 br3-ns nt4 mb5`}
            >
              <section>
                <MemorialNavBar
                  activeTab={activeTab}
                  handleTabChange={this.handleTabChange}
                  shouldShowPublicTributes={socialPosts.length > 0}
                />
              </section>
              <div className="cf ph3">
                {activeTab === 'memories' && (
                  <div>
                    <div className="masonry">
                      {tributes.reverse().map((tribute, index) => {
                        const {
                          dateCreated,
                          eventDay,
                          eventMonth,
                          eventYear,
                          eventDate,
                          image,
                          message,
                          quote,
                          quoteBackgroundColor,
                          quoteBackgroundImage,
                          quoteFontColor,
                          type,
                          venueAddress,
                          venueDateTime,
                          venueName,
                          videoUrl,
                        } = tribute

                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={index} className="masonry-item relative w-100 pa2 ma0 mb2">
                            <div className="">
                              {type === 'FuneralInfo' && (
                                <FuneralInfo
                                  message={message}
                                  type="memorial"
                                  venueAddress={venueAddress}
                                  venueDateTime={venueDateTime.toDate()}
                                  venueName={venueName}
                                />
                              )}
                              {type === 'ImageAndMessage' && (
                                <ImageAndMessage
                                  eventDate={eventDate}
                                  eventDay={eventDay}
                                  eventMonth={eventMonth}
                                  eventYear={eventYear}
                                  image={image}
                                  message={message}
                                  type="memorial"
                                />
                              )}
                              {type === 'ImageOnly' && <ImageOnly image={image} />}
                              {type === 'MessageOnly' && (
                                <MessageOnly
                                  eventDate={eventDate}
                                  eventDay={eventDay}
                                  eventMonth={eventMonth}
                                  eventYear={eventYear}
                                  message={message}
                                  type="memorial"
                                />
                              )}
                              {type === 'QuoteAndMessage' && (
                                <QuoteAndMessage
                                  eventDate={eventDate}
                                  eventDay={eventDay}
                                  eventMonth={eventMonth}
                                  eventYear={eventYear}
                                  message={message}
                                  quote={quote}
                                  quoteBackgroundColor={quoteBackgroundColor}
                                  quoteBackgroundImage={quoteBackgroundImage}
                                  quoteFontColor={quoteFontColor}
                                  type="memorial"
                                />
                              )}
                              {type === 'YoutubeVideo' && (
                                <YoutubeVideo
                                  eventDate={eventDate}
                                  eventDay={eventDay}
                                  eventMonth={eventMonth}
                                  eventYear={eventYear}
                                  videoUrl={videoUrl}
                                />
                              )}
                              <p className="tr f6 mid-gray">
                                Publicado há&nbsp;
                                {formatDistance(new Date(dateCreated.seconds * 1000), new Date(), {
                                  locale: ptBR,
                                })}
                              </p>
                            </div>
                          </div>
                        )
                      })}
                      <div className="w-100 ba tc pa3 pa4-ns bg-orange white br3 mb3">
                        <p className="mt0 mb3 mb4-ns lh-copy">
                          Preste sua homenagem a
                          <br className="db dn-ns" />
                          <span className="dn dib-ns ml1" />
                          {personName}.
                        </p>
                        <button
                          type="button"
                          className="dib btn btn-white-secondary br-pill f6 w-auto center fw4"
                          onClick={() => this.setState({ activeTab: 'guestbook' })}
                        >
                          Assine o Livro de Visitas
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === 'timeline' && (
                  <div>
                    <section
                      id="timeline"
                      className="timeline-container relative pt4 mb4 mw-100 overflow-hidden ph2-ns"
                    >
                      {tributes
                        .filter(item => item.eventYear !== 0)
                        .sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))
                        .map((tribute, index) => {
                          if (year !== tribute.eventYear) {
                            year = tribute.eventYear
                            anchors.push(`year${year}`)
                          } else {
                            year = null
                          }
                          return (
                            <TimelineItem
                              id={year ? `year${year}` : randomString(5)}
                              key={index}
                              tribute={tribute}
                            />
                          )
                        })}
                    </section>
                    <div className="w-100 ba tc pa3 pa4-ns bg-orange white br3 mb3">
                      <p className="mt0 mb3 mb4-ns lh-copy">
                        Preste sua homenagem a
                        <br className="db dn-ns" />
                        <span className="dn dib-ns ml1" />
                        {personName}.
                      </p>
                      <button
                        type="button"
                        className="dib btn btn-white-secondary br-pill f6 w-auto center fw4"
                        onClick={() => this.setState({ activeTab: 'guestbook' })}
                      >
                        Assine o Livro de Visitas
                      </button>
                    </div>
                  </div>
                )}
                {activeTab === 'public-tributes' && (
                  <div className="tc nt4">
                    <div className="masonry">
                      {socialPosts.map((socialPost, index) => {
                        let type = 'twitter'
                        if (socialPost.indexOf('facebook.com') >= 0) {
                          type = 'facebook'
                        } else if (socialPost.indexOf('instagr') >= 0) {
                          type = 'instagram'
                        }
                        let href
                        switch (type) {
                          case 'twitter':
                            href = socialPost.substr(socialPost.lastIndexOf('/') + 1)
                            break
                          default:
                            href = socialPost
                            break
                        }
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={index} className="masonry-item relative w-100 pa2 pt4 ma0 mb2">
                            {type === 'facebook' && (
                              <FacebookProvider appId="996393560749580">
                                <EmbeddedPost href={href} width="auto" />
                              </FacebookProvider>
                            )}
                            {type === 'twitter' && <TwitterTweetEmbed tweetId={href} />}
                            {type === 'instagram' && (
                              <InstagramEmbed
                                url={href}
                                hideCaption={false}
                                containerTagName="div"
                                injectScript
                              />
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
                {activeTab === 'guestbook' && (
                  <div className="mv3 tc">
                    {guests.filter(item => item.message.trim() !== '').length > 0 && (
                      <div className="mb4 cf">
                        <div className="masonry">
                          {guests
                            .filter(item => item.message.trim() !== '')
                            .reverse()
                            .map((guestbookEntryWithMessage, index) => {
                              const { message, user } = guestbookEntryWithMessage
                              const { name, socialId } = user
                              return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div
                                  key={index}
                                  className="masonry-item relative w-100 pa2 pt4 ma0 mb2"
                                >
                                  <GuestbookEntry
                                    name={name}
                                    photo={`https://graph.facebook.com/${socialId}/picture?type=normal&width=200&height=200`}
                                    dateCreated={guestbookEntryWithMessage.dateCreated}
                                    message={message}
                                  />
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    )}
                    <div className="cf">
                      <ul className="tc mt4-ns ma0 pa0 list flex flex-wrap justify-center">
                        {guests
                          .filter(item => item.message.trim() === '')
                          .reverse()
                          .map((guestbookEntry, index) => {
                            const { user } = guestbookEntry
                            const { name, socialId } = user
                            return (
                              <li key={guestbookEntry.user.id} className="dib w4 mh3 mb4">
                                <img
                                  alt={name}
                                  className="br-pill w4 h4 db"
                                  src={`https://graph.facebook.com/${socialId}/picture?type=square&width=200&height=200`}
                                />
                                <h3 className="f3 fw4 mt2 mb2">{name}</h3>
                              </li>
                            )
                          })}
                      </ul>
                    </div>
                    <AuthUserContext.Consumer>
                      {authUser =>
                        authUser ? (
                          <div>
                            {state === 'guestbookSigned' && (
                              <div className="mb5">
                                <h2 className="fw4 mb4">
                                  Obrigado por assinar o Livro de Visitas de&nbsp;
                                  {personName}
                                </h2>
                                <p>Em breve ele será disponibilizado no memorial.</p>
                              </div>
                            )}
                            {!hasAlreadySigned && state !== 'guestbookSigned' && (
                              <div>
                                <hr className="bt b--light-gray mv4" />
                                <h2 className="fw4 mb4">
                                  Deixe também sua homensagem a&nbsp;
                                  {personName}
                                  <br className="db dn-ns" />
                                </h2>
                                {state === 'guestbookTypeChoice' && (
                                  <div className="flex flex-wrap ph3-ns flex-nowrap-ns mv4 items-center">
                                    <div className="w-100 w-50-ns">
                                      <button
                                        type="button"
                                        className="btn btn-primary outline-0"
                                        onClick={this.addToGuestBook}
                                      >
                                        Assine o Livro de Visitas
                                      </button>
                                    </div>
                                    <div className="nowrap ph4">- ou -</div>
                                    <div className="w-100 w-50-ns">
                                      <button
                                        type="button"
                                        className="btn btn-primary outline-0x"
                                        onClick={() =>
                                          this.setState({ state: 'guestbookWithMessage' })
                                        }
                                      >
                                        Publique uma mensagem
                                      </button>
                                    </div>
                                  </div>
                                )}
                                {state === 'guestbookWithMessage' && (
                                  <div className="flex w-100 flex-wrap justify-end">
                                    <div className="textarea mb3 w-100">
                                      <textarea
                                        ref={c => {
                                          this.guestMessage = c
                                        }}
                                        id="guestMessage"
                                        className="filled lh-copy f6"
                                        defaultValue={guestMessage}
                                        onChange={e => {
                                          this.setState({
                                            guestMessage: e.target.value,
                                          })
                                        }}
                                      />
                                      <label htmlFor="message">Mensagem</label>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-primary outline-0x"
                                      onClick={this.addToGuestBook}
                                    >
                                      Publicar Mensagem
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="mb5">
                            <h2 className="fw4">
                              Acesse com sua conta no Facebook para assinar o Livro de Visitas
                            </h2>
                            <SignInFacebook
                              onSignIn={() => this.setState({ activeTab: 'guestbook' })}
                            />
                          </div>
                        )
                      }
                    </AuthUserContext.Consumer>
                  </div>
                )}
              </div>
            </div>
            {activeTab === 'timeline' && (
              <div id="timeline-navigation" className="w-10 dn db-ns">
                <ul className="list ma0 mt3 pa0 sticky" items={anchors}>
                  {tributes
                    .filter(item => item.eventYear !== 0)
                    .sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))
                    .map((tribute, index) => {
                      if (year !== tribute.eventYear) {
                        year = tribute.eventYear
                        return (
                          <li
                            key={index}
                            className="pl1 pl3-ns pv2 bl f7 f5-ns b--transparent"
                            style={{ borderWidth: '4px' }}
                          >
                            <Scrollchor
                              to={`#year${tribute.eventYear}`}
                              className="pl1 pl2-ns f7 f5-ns no-underline black outline-0 bg-transparent b--red mv3 pointer bn b--red"
                            >
                              {tribute.eventYear}
                            </Scrollchor>
                          </li>
                        )
                      }
                      return <li key={randomString(5)} className="dn" />
                    })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default injectIntl(Memorial)

export const pageQuery = graphql`
  query memorialQuery($id: String!) {
    person(id: { eq: $id }) {
      id
      birthday(formatString: "YYYY-MM-DDTHH:mm:ss.SSSZ")
      deathday(formatString: "YYYY-MM-DDTHH:mm:ss.SSSZ")
      available
      city
      personName
      private
      memorial {
        available
        guests {
          approved
          dateCreated
          message
          user {
            id
            name
            socialId
          }
        }
        id
        memorialBackgroundColor
        memorialIntroduction
        personPhoto
        preApprovalRequired
        socialPosts
        tributes {
          dateCreated {
            nanoseconds
            seconds
          }
          eventDate {
            nanoseconds
            seconds
          }
          eventDay
          eventMonth
          eventYear
          image
          message
          quote
          quoteBackgroundColor
          quoteBackgroundImage
          quoteFontColor
          type
          videoUrl
        }
        userEmail
      }
    }
  }
`
